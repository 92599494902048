import * as React from 'react';
import { WithChildren } from '../types';
import { IcoAnimated as ComponentType, IcoAnimatedProps } from '../animated-icon/AnimatedIcon';
import dynamic from 'next/dynamic';
import { Button } from 'src/design-system/button/Button';
import styles from './buttons.module.scss';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { getColorStyle } from '@utmb/utils/colors';
import { ColorTypesEnum } from '@utmb/types/colors';
import { Icon } from '../icons/Icon';
import { IconsEnum } from '@utmb/types/icons';
const NoSSRAnimatedIcon = dynamic<IcoAnimatedProps>(() => import('../animated-icon/AnimatedIcon').then((comp) => comp.IcoAnimated as any), {
    ssr: false,
}) as typeof ComponentType;

interface ButtonProps {
    className?: string;
    enabled?: boolean;
    onPress: () => void;
    color?: string;
}

interface DotButtonProps {
    className?: string;
    selected?: boolean;
    onClick: () => void;
}

export const PrevButton = ({ enabled = true, onPress, className }: ButtonProps & WithChildren) => {
    const buttonClassNames = ['embla__button embla__button--prev', styles.slider_previous_btn].filter(Boolean).join(' ');
    return (
        <button className={[buttonClassNames, className].join(' ')} onClick={onPress} disabled={!enabled} type="button">
            <NoSSRAnimatedIcon rotation={0} timing={0}>
                {/* NextIcon is used with rotate 180deg untill we get final assets */}
                <Icon variant={IconsEnum.NEXT} color={getColorStyle(ColorTypesEnum.DEFAULT)} />
            </NoSSRAnimatedIcon>
        </button>
    );
};

export const NextButton = ({ enabled = true, onPress, className }: ButtonProps & WithChildren) => {
    const buttonClassNames = ['embla__button embla__button--next', styles.slider_next_btn].filter(Boolean).join(' ');
    return (
        <button className={[buttonClassNames, className].join(' ')} onClick={onPress} disabled={!enabled} type="button">
            <NoSSRAnimatedIcon rotation={0} timing={0}>
                <Icon variant={IconsEnum.NEXT} color={getColorStyle(ColorTypesEnum.DEFAULT)} />
            </NoSSRAnimatedIcon>
        </button>
    );
};

export const DotButton = ({ selected, onClick }: DotButtonProps & WithChildren) => (
    <button className={`embla__dot ${selected ? 'is-selected' : ''}`} type="button" onClick={onClick} />
);

interface RealDotButtonProps {
    selected?: boolean;
    cb: () => void;
}
export const TheRealDotButton = ({ selected, cb }: RealDotButtonProps) => {
    const classNames = [styles.dot, selected && styles.selected].filter(Boolean).join(' ');
    return (
        <Button.Ghost className={classNames} onPress={cb}>
            {' '}
            <VisuallyHidden> go to slide </VisuallyHidden>{' '}
        </Button.Ghost>
    );
};
