import * as React from 'react';
import dynamic from 'next/dynamic';
// Modules
import classNames from 'classnames/bind';
// Features
import { SocialShare } from '@utmb/features/layout/social-share/SocialShare';
// Design systems
import { Box, Col } from '@utmb/design-system/box/Box';
import { Button } from '@utmb/design-system/button/Button';
import { Font } from '@utmb/design-system/text/font';
import { IcoAnimated as ComponentType, IcoAnimatedProps } from '@utmb/design-system/animated-icon/AnimatedIcon';
import { Icon } from '@utmb/design-system/icons/Icon';
import { Image } from '@utmb/design-system/image/Image';
import { Pin } from '@utmb/design-system/pin/Pin';
import { Spacer } from '@utmb/design-system/spacer/Spacer';
import { Link } from '@utmb/design-system/link/link';
import { EventSlider } from './slider';
// Interfaces
import { GlobalEventView, HomePageHeaderView } from '@utmb/types/index';
import { IconsEnum } from '@utmb/types/icons';
import { LinkTypeEnum } from '@utmb/types/enum/LinkTypeEnum';
import { MicroDataEvent } from '@utmb/types/microdata';
// Hooks
import { useGlobal } from '@utmb/contexts/GlobalContext';
// Styles
import styles from './page-header.module.scss';
import { useCloudinary } from '@utmb/design-system/use-cloudinary/UseCloudinary';
import { ImageFilterEnum } from '@utmb/types/enum/ImageFilterEnum';
import { CloudinaryTransformEnum } from '@utmb/types/cloudinary-transform.enum';
import { ColorTypesEnum } from '@utmb/types/colors';
import { getColorAndBgColorButton } from '@utmb/utils/colors';

const cx = classNames.bind(styles);

// -----------------------------------------------------------------------------------------------------------------------------------------------------
//
// Page Header - NoSSR

const NoSSRAnimatedIcon = dynamic<IcoAnimatedProps>(() => import('../../design-system/animated-icon/AnimatedIcon').then((comp) => comp.IcoAnimated as never), {
    ssr: false,
}) as typeof ComponentType;

// -----------------------------------------------------------------------------------------------------------------------------------------------------
//
// Page Header

export const PageHeader = (props: HomePageHeaderView & GlobalEventView): React.ReactElement => {
    //
    // Props
    const { alerts, background, cta, link, race, summary, title, siteLogo, siteLogoDark, imageFilter } = props;
    const { global } = useGlobal();
    const { getImage } = useCloudinary();

    const LinkComponent = link && link.type === LinkTypeEnum.LINK ? Link.Default : Link.Button;
    const linkCSS = cx(styles.external_link, {
        is_button: link && link.type === LinkTypeEnum.BUTTON,
    });

    const isWS = !!global?.event?.isWS;
    const {
        event: { primaryColorIsDark, secondaryColorIsDark },
    } = global;

    //
    // Components
    const RaceFontComponent = isWS ? Font.FuturaBook : Font.FuturaHeavy;

    //
    // CSS Variables
    const raceCSS = cx('font-white', 'font-uppercase', 'ls-1', styles.race_CSS);
    const titleCSS = cx(styles.ph_title, {
        ph_title_ws: isWS,
    });
    const summaryCSS = cx('font-white', 'lh-30', 'lh-d-50', 'fw-5', styles.ph_summary, {
        ph_summary_ws: isWS,
    });
    const socialShareCSS = cx(styles.social_share, {
        is_dark: global?.event?.primaryColorIsDark,
    });

    const microdataEvent: MicroDataEvent | null = global?.event?.begin
        ? {
              '@context': 'https://schema.org',
              '@type': 'Event',
              name: global.event.name,
              description: summary,
              startDate: global.event.begin.substring(0, 10),
              endDate: global.event.end.substring(0, 10),
              eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
              eventStatus: 'https://schema.org/EventScheduled',
              location: {
                  '@type': 'Place',
                  name: global.event.placeName,
              },
              image: [getImage(siteLogo.publicId, { maxWidth: 720 }), getImage(background.publicId, { maxWidth: 1920 })],
          }
        : null;

    //
    // JSX
    return (
        <>
            {/* microdata */}
            {microdataEvent && <script type="application/ld+json">{JSON.stringify(microdataEvent)}</script>}
            {/* Container */}
            <Col className={styles.container}>
                {/* BG Image */}
                <Image
                    objectFit="cover"
                    className={[styles.img, imageFilter === ImageFilterEnum.SIMPLE_ALPHA ? styles.filter : null].filter(Boolean).join(' ')}
                    image={background}
                    objectPosition="center center"
                    addOptions={imageFilter === ImageFilterEnum.EPIC ? [CloudinaryTransformEnum.EPIC] : undefined}
                />

                {/* 1 - MAIN CONTENT */}
                <section className={styles.page_header_pcenter}>
                    {/* [ 1 - RACE ] */}
                    {isWS && race && (
                        <div className={styles.mobile_hidden}>
                            <RaceFontComponent mobile="12" desktop="16" className={raceCSS}>
                                {race}
                            </RaceFontComponent>
                            <Spacer size={isWS ? 'default' : 's30'} />
                        </div>
                    )}

                    {!isWS && (
                        <div className={styles.image_container}>
                            <div className={styles.image_wrapper}>
                                <Image objectFit="contain" image={siteLogoDark ?? siteLogo} />
                            </div>
                        </div>
                    )}

                    {/* [ 2 - TITLE ] */}
                    {isWS && title && (
                        <>
                            <Font.FuturaBook mobile="32" desktop="80" className={titleCSS} as="h1">
                                {title}
                            </Font.FuturaBook>
                        </>
                    )}

                    {/* [ 3 - SUMMARY ] */}
                    {summary && (
                        <div>
                            <Spacer size={isWS ? 's40' : 'default'} />
                            <Font.FuturaLight mobile="18" desktop="36" className={summaryCSS}>
                                {summary}
                            </Font.FuturaLight>
                        </div>
                    )}
                </section>

                {/* [ 4 - Alerts ] */}
                {alerts && !!alerts.length && (
                    <Pin className={styles.container_slider} xy={{ bottom: 0, right: 0 }}>
                        <EventSlider slides={alerts} />
                    </Pin>
                )}

                {/* [ 5 - SOCIAL MEDIA ] */}
                <Pin className={socialShareCSS} xy={{ top: '50%', right: '20px', transform: 'translateY(-50%)' }}>
                    <SocialShare.Vertical className="landscape-only" />
                </Pin>

                {/* [ 6 - TRIANGLES ] */}
                <div className={styles.v_triangle} />

                {/* [ 7 - CTA + Arrow ] */}
                <Pin className={styles.style_block} xy={{ bottom: '0px', left: '0px' }}>
                    <div className={styles.page_header_bottom}>
                        {cta && link && (
                            <div className={styles.main_cta}>
                                <LinkComponent
                                    className={linkCSS}
                                    href={link.slug}
                                    label={link.label}
                                    followClassName={`${link.followClass}-ga`}
                                    bgcolor={ColorTypesEnum.SECONDARY}
                                    color={getColorAndBgColorButton(primaryColorIsDark, secondaryColorIsDark, ColorTypesEnum.SECONDARY)[0]}
                                />
                            </div>
                        )}
                        <Button.Ghost className={styles.cta_scroll} onPress={() => window.scrollTo(0, window.innerHeight - 100)}>
                            <NoSSRAnimatedIcon rotation={20} timing={200}>
                                <Icon variant={IconsEnum.DOWN} color="white" />
                            </NoSSRAnimatedIcon>
                        </Button.Ghost>
                    </div>
                </Pin>
            </Col>

            {/* [ 8 - EVENT SLIDER ] */}
            {alerts?.length > 0 && (
                <Box className={styles.portrait}>
                    <EventSlider slides={alerts} />
                </Box>
            )}
        </>
    );
};
