// React
import * as React from 'react';
//  Modules
import classNames from 'classnames/bind';
import { EmblaCarouselType } from 'embla-carousel';
import { useEmblaCarousel } from 'embla-carousel/react';
//  Design systems
import { DotButton, PrevButton, NextButton, TheRealDotButton } from './buttons';
import { Pin, PinAllowedStyles } from '../pin/Pin';
import { CoreProps, WithChildren, RenderProp } from '../types';
import { Row } from '../box/Box';
// Utils
import { v4 as uuidv4 } from 'uuid';
// Exports
export { PrevButton, NextButton };

/*
TIPS
----
embla.reInit({ ...updateOpts })
Parallax => https://codesandbox.io/s/embla-carousel-parallax-react-ep71f?file=/src/js/EmblaCarousel.js

*/

export interface SlideProps {
    cb?: () => void;
    controls?: boolean;
    pagination?: boolean;
    isDarkPagination?: boolean;
    dots?: boolean;
    dotsClassName?: string;
    children?: RenderProp<{
        embla: EmblaCarouselType | undefined;
        currentSlide: number;
    }>;
    className?: string;
    composeClassName?: string;
    carouselOptions?: {
        align?: 'start' | 'center' | 'end';
        axis?: 'x' | 'y';
        containScroll?: 'trimSnaps' | 'keepSnaps';
        direction?: 'ltr' | 'rtl';
        draggable?: boolean;
        loop?: boolean;
        slidesToScroll?: number;
        startIndex?: number;
    };
    header?: RenderProp<{ embla: EmblaCarouselType | undefined; currentSlide: number }> | false;
    prevXY?: Partial<PinAllowedStyles>;
    nextXY?: Partial<PinAllowedStyles>;
    containerWidth?: number;
}

export const Slides = (props: SlideProps) => {
    const {
        children,
        className,
        dots = false,
        dotsClassName = undefined,
        pagination = false,
        isDarkPagination = false,
        controls = false,
        header = false,
        carouselOptions,
        nextXY = { top: '50%', transform: 'translate(0,-50%)' },
        prevXY = { top: '50%', transform: 'translate(0,-50%)' },
        containerWidth,
    } = props;

    const [emblaRef, embla]: any = useEmblaCarousel({
        startIndex: 0,
        loop: false,
        draggable: false,
        align: 'center',
        selectedClass: 'slide-item-selected',
        ...carouselOptions,
    });
    const [prevBtnEnabled, setPrevBtnEnabled] = React.useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = React.useState(false);
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [scrollSnaps, setScrollSnaps] = React.useState([]);

    if (!children) return null;

    let scrollSnapsItems: [] = [];

    const scrollPrev = React.useCallback(() => embla && embla.scrollPrev(), [embla]);
    const scrollNext = React.useCallback(() => embla && embla.scrollNext(), [embla]);
    const scrollTo = React.useCallback((index) => embla && embla.scrollTo(index), [embla]);

    const onSelect = React.useCallback(() => {
        if (!embla) return;

        setSelectedIndex(embla.selectedScrollSnap());
        setPrevBtnEnabled(embla.canScrollPrev());
        setNextBtnEnabled(embla.canScrollNext());
        setCurrentSlide(embla.selectedScrollSnap());
    }, [embla]);

    React.useEffect(() => {
        if (!embla) return;

        scrollSnapsItems = embla.scrollSnapList();
        setScrollSnaps(scrollSnapsItems);
        onSelect();
        embla.on('select', onSelect);
    }, [embla, setScrollSnaps, onSelect]);

    const viewportCSS = classNames('embla__viewport', className);
    const dotsCSS = classNames(dotsClassName, 'embla__dots', {
        embla__dark__dots: isDarkPagination,
    });

    // Calculate `containerWidth` to center the slides
    const containerStyle = {
        width: containerWidth && carouselOptions?.align === 'center' ? `${containerWidth}px` : '100%',
    };

    return (
        <React.Fragment>
            {header &&
                header({
                    embla,
                    currentSlide,
                })}
            <div className={viewportCSS} ref={emblaRef}>
                <div className="embla__container" style={containerStyle}>
                    {children({
                        embla,
                        currentSlide,
                    })}
                </div>

                {controls && (
                    <React.Fragment>
                        <Pin xy={prevXY}>{prevBtnEnabled && <PrevButton onPress={scrollPrev} enabled={prevBtnEnabled} />}</Pin>
                        <Pin xy={nextXY}>{nextBtnEnabled && <NextButton onPress={scrollNext} enabled={nextBtnEnabled} />}</Pin>
                    </React.Fragment>
                )}
            </div>

            {pagination && (
                <Row className="_embla__pagination">
                    <div className={dotsCSS}>
                        {scrollSnaps.map((_item, index: any) => (
                            <DotButton key={uuidv4()} selected={index === selectedIndex} onClick={() => scrollTo(index)} />
                        ))}
                    </div>
                </Row>
            )}
            {dots && (
                <div className={dotsClassName}>
                    {scrollSnaps.map((_item, index: number) => (
                        <TheRealDotButton key={uuidv4()} selected={index === selectedIndex} cb={() => scrollTo(index)} />
                    ))}
                </div>
            )}
        </React.Fragment>
    );
};

type Clickable = {
    onClick?: () => void;
    onKeyDown?: () => void;
    tabIndex?: number;
    role?: string;
};
type SlidesItemProps = CoreProps & WithChildren & Clickable;

export const SlidesItem = (props: SlidesItemProps) => {
    const { className, composeClassName, children, ...rest } = props;
    const slideClasses = ['embla__slide', className, composeClassName].filter(Boolean).join(' ');
    return (
        <div className={slideClasses} {...rest}>
            {children}
        </div>
    );
};
