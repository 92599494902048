import React from 'react';
import { GetServerSideProps, NextPage } from 'next';
//import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
// import dynamic from 'next/dynamic';
// Features
import { Layout } from '@utmb/layouts/layout-base';
//import { CreateYourAccount } from '@utmb/features/create-your-account/CreateYourAccount';
import { PageHeader } from '@utmb/features/page-header/PageHeader';
import { SlicesHandler } from '@utmb/features/slices-handler/SlicesHandler';
// import { Auth } from "@utmb/features/sso/Auth";
// Interfaces
import { HomeView, LangEnum, PageDefaultProps } from '@utmb/types/index';
// Context, utils
import { i18n } from 'next-i18next.config';
import { PageProviders } from '@utmb/contexts/index';
import isMobile from '@utmb/utils/is-mobile';
import isTablet from '@utmb/utils/is-tablet';
import { fetchAPI } from '@utmb/utils/utmb';

interface PageProps extends PageDefaultProps, HomeView {}

const IndexPage: NextPage<PageProps> = ({
    contentLocale,
    event,
    eventsTopBar,
    footer,
    isMobile,
    mobileBottomBar,
    navigation,
    simpleMenu,
    pageHeader,
    seo,
    slices,
    partners,
    languages,
    host,
}) => {
    //const { t } = useTranslation();

    return (
        <PageProviders>
            <Layout
                {...seo}
                contentLocale={contentLocale}
                isMobile={isMobile}
                eventsTopBar={eventsTopBar}
                mobileBottomBar={mobileBottomBar}
                navigation={navigation}
                simpleMenu={simpleMenu}
                footer={footer}
                host={host}
                landing={true}
                event={event}
                partners={partners}
                languages={languages}
                menuLogoIsDark={true}
            >
                {pageHeader && <PageHeader {...event} {...pageHeader} />}

                <SlicesHandler slices={slices} />

                {/*<CreateYourAccount
                    title={t('createYourAccount.title')}
                    content={t('createYourAccount.content')}
                    buttonLabel={t('createYourAccount.buttonLabel')}
                    buttonLink="/"
                />*/}
            </Layout>
        </PageProviders>
    );
};

export default IndexPage;

export const getServerSideProps: GetServerSideProps<HomeView> = async ({ locale, defaultLocale, req }) => {
    try {
        // Translate
        const lang: LangEnum = (locale || defaultLocale || i18n.defaultLocale) as LangEnum;
        const translations = await serverSideTranslations(lang, ['common']);

        const host = req.headers.host;

        // Page Content
        const page = await fetchAPI(req, '/content/home', { lang });
        // if error
        if (!page) return { notFound: true };
        const { pageHeader, seo, slices, locale: contentLocale } = page;

        return {
            props: {
                contentLocale,
                host,
                isMobile: isMobile(req) || isTablet(req),
                locale: lang,
                pageHeader,
                seo,
                slices,
                ...translations,
            },
        };
    } catch (error) {
        return {
            notFound: true,
        };
    }
};
