import { ReactElement } from 'react';
// Next
import Link from 'next/link';
// Design system
import { Col, Row } from 'src/design-system/box/Box';
import { Icon } from '@utmb/design-system/icons/Icon';
import { Image } from 'src/design-system/image/Image';
import { Separator } from 'src/design-system/separator/Separator';
import { Slides, SlidesItem } from 'src/design-system/slides/Slides';
import { Spacer } from 'src/design-system/spacer/Spacer';
import { Font } from 'src/design-system/text/font';
// Interfaces
import { PageHeaderAlertView } from 'src/types';
// Styles
import styles from './slider.module.scss';
import { IconsEnum } from '@utmb/types/icons';

export interface SliderProps {
    slides: PageHeaderAlertView[];
}

export const EventSlider = (props: SliderProps): ReactElement => {
    const { slides } = props;
    const customButtonPrevClassNames = [styles.quiet, styles.prev].filter(Boolean).join(' ');
    const customButtonNextClassNames = [styles.quiet, styles.next].filter(Boolean).join(' ');

    return (
        <Slides
            className={styles.container}
            carouselOptions={{
                containScroll: 'trimSnaps',
                align: 'start',
                draggable: true,
            }}
        >
            {({ embla }) => (
                <>
                    {slides.map((slide: PageHeaderAlertView, i: number) => (
                        <SlidesItem key={i} className={styles['slide-item']}>
                            <EventSlide {...slide} />
                            {embla?.canScrollPrev() && (
                                <button type="button" className={customButtonPrevClassNames} onClick={() => embla?.scrollPrev()}>
                                    <Icon variant={IconsEnum.LEFT} />
                                </button>
                            )}
                            {embla?.canScrollNext() && (
                                <button type="button" className={customButtonNextClassNames} onClick={() => embla?.scrollNext()}>
                                    <Icon variant={IconsEnum.RIGHT} />
                                </button>
                            )}
                        </SlidesItem>
                    ))}
                </>
            )}
        </Slides>
    );
};

export const EventSlide = (props: PageHeaderAlertView): ReactElement => {
    const { link, followClass } = props;

    if (link) {
        const target = link.startsWith('http') ? '_blank' : '_self';
        return (
            <Link href={link}>
                <a className={followClass ?? ''} target={target}>
                    <EventSlideInner {...props} />
                </a>
            </Link>
        );
    } else {
        return <EventSlideInner {...props} />;
    }
};

export const EventSlideInner = (props: PageHeaderAlertView): ReactElement => {
    const { title, summary, picture } = props;

    const maxLength = 62;

    return (
        <Row className={styles.slide}>
            {!picture ? null : (
                <div className={styles.container_picture}>
                    <Image objectFit="cover" layout="fill" image={picture} alt={title} maxWidth={300} />
                </div>
            )}
            <Col className={styles.card}>
                <Font.FuturaHeavy mobile="14" desktop="14">
                    {title}
                </Font.FuturaHeavy>
                <Separator depth="20%" />
                <Spacer custom="10" />
                <Font.FuturaBook mobile="12" className="lh-20">
                    {summary?.length > maxLength ? `${summary.substring(0, maxLength).trim()}...` : summary}
                </Font.FuturaBook>
            </Col>
        </Row>
    );
};
